import React from 'react'
import { Grid } from '@mui/material'
import anos from './foot.png'
import uni from './ray.png'
import dex from './ds.png'

import noot1c from './Com.jpeg'

import noot1 from './w1.jpeg'
import noot2 from './w2.jpeg'
import noot3 from './w3.jpeg'



import phantom from './phantom.png'
import solana from './solana.png'



function Desktop() {
  return (
    <div className='container'>
      <br/>
      <Grid container>
            
            <Grid item md={12} xs={12} lg={12} className='centerall a'>
            <a href="" class="matrix-text matrix-text_fontSize"
            style={{textDecoration:"none"}}
            
            >
        C<span className='fontSize_span'>HART</span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
           <a href="" class="matrix-text matrix-text_fontSize"
            style={{textDecoration:"none"}}
            
            >   T<span className='fontSize_span'>WITTER</span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
               
               <a href="" class="matrix-text matrix-text_fontSize"
            style={{textDecoration:"none"}}
            
            >   T<span className='fontSize_span'>ELEGRAM</span></a>
  
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="" class="matrix-text matrix-text_fontSize"
            style={{textDecoration:"none"}}
            id="blinking-text"

            
            >   B<span className='fontSize_span'>UY</span></a>

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <a href="./LaserMe" class="matrix-text matrix-text_fontSize"
            style={{textDecoration:"none"}}
         
            
            
            >   L<span className='fontSize_span'>ASER</span>  M<span className='fontSize_span'>E</span></a>
           </Grid>
            </Grid>
   
            <br/>  <br/> <br/>  <br/>

          <Grid container>
          <Grid item md={6} xs={12} lg={6} className='centerall'>  <br/>  <br/> <br/>  <br/>
          <p class="matrix-text anos_text"
          style={{textDecoration:"none"}}
          
          >  B<span className='fontSize_span_anos_text'>ERKSHIRE</span> H<span className='fontSize_span_anos_text'>ATHAWAY</span> <span className='fontSize_span_anos_text'>INC</span></p>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">$BRK</h1>
          <br/>  <br/>
          <Grid container>
          <Grid item md={6} xs={6} lg={6} className='centerall'>
          <a href=''>   <img src={uni} className='image_anos_uni'/></a>
       
          <br/>  <br/>

          
         </Grid>
         <Grid item md={6} xs={6} lg={6} className='centerall dextop'>
         <a href=''>  <img src={dex} className='image_anos_dex' style={{position:"relative" , bottom:"10px"}}/></a>
        
          <br/>  <br/>
        
         </Grid>
          </Grid>
         </Grid>
          <Grid item md={6} xs={12} lg={6} className='centerall'>
                <img src={anos} className='image_anos'/>
         </Grid>
      
      
          </Grid>

          <br/>  <br/>

       
        

       

          
       


          <br/>  <br/>


          
<Grid container id='Tokenomics'>
<Grid item md={12} xs={12} lg={12} className='centerall'>
</Grid>

<Grid item md={12} xs={12} lg={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20"><div class="flex justify-center"><div class="card"><div class="boarding"></div><div class="card-content"><h2 class="matrix-text card-title py-2 tokcolo">


<p class="matrix-text anos_text"
          style={{textDecoration:"none"}}
          
          >  C<span className='fontSize_span_anos_text'>ontract</span> A<span className='fontSize_span_anos_text'>ddress</span> <span className='fontSize_span_anos_text'></span></p>

  
  
  <p>wait please</p></h2></div></div></div></div>
</Grid>

</Grid>

<br/>  <br/>
<Grid container spacing={4} id='Tokenomics'>
<Grid item lg={6} md={6} xs={12} sm={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">
      <img src={noot1c} style={{width:"100%"}}/>
        
        </div></div></div></div>

</Grid>
<Grid item lg={6} md={6} xs={12} sm={12} className='centerall'>
  <br/>
  <br/>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">
      <p className='anon_pp' > 


  <br/>
      
      Introducing $BRK the Solana Meme Coin inspired by Warren Buffet, and his company, Berkshire Hathaway. As a communtiy driven meme coin we are here to HODL, get rich and stunt on Solana like Warren himself. You only need one coin, choose the right one.


  <br/>
      
      </p>
        </div></div></div></div>
        <br/>
        <br/>

</Grid>


</Grid>
<br/>  <br/>













<Grid container>
          <Grid item md={12} xs={12} lg={12} className='centerall'>  
          <p class="matrix-text anos_text"
          style={{textDecoration:"none"}}
          
          >  H<span className='fontSize_span_anos_text'>OW</span> T<span className='fontSize_span_anos_text'>O</span> B<span className='fontSize_span_anos_text'>UY</span> </p>
          <h1 class="opacity-bg matrix-text text-4xl md:text-5xl mb-4 text-center anos_text">Its super easy to acquire $BRK</h1>
          <br/>  <br/>
          <Grid container>
          
          </Grid>
         </Grid>
       





      
          </Grid>


<Grid container>
<Grid item lg={12} md={12} xs={12} sm={12} className='centerall'>
  <br/>
  <br/>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">


        
      <p className='anon_p'> 
  <br/>
  <br/>
  Create Wallet
  <br/>
  <br/>
      
  Download and install a wallet like Phantom on your browser, or if on mobile from the App Store or Google Play Store.
  <br/>
  <br/>
<a href='https://phantom.app/'>  <img src={phantom} style={{width:"10%" , borderRadius:"20px"}}/></a>

  <br/>
  <br/>
      
      </p>
        </div></div></div></div>
        <br/>
        <br/>

</Grid>





<Grid item lg={12} md={12} xs={12} sm={12} className='centerall'>
  <br/>
  <br/>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">


        
      <p className='anon_p'> 
  <br/>
  <br/>
  Buy Solana
  <br/>
  <br/>
      
  $BRK is on the Solana blockchain so you need Solana to purchase. You can purchase Solana inside wallets or withdraw from an exchange.
  <br/>
  <br/>
<a href=''>  <img src={solana} style={{width:"10%" , borderRadius:"20px"}}/></a>

  <br/>
  <br/>
      
      </p>
        </div></div></div></div>
        <br/>
        <br/>

</Grid>


<Grid item lg={12} md={12} xs={12} sm={12} className='centerall'>
  <br/>
  <br/>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">


        
      <p className='anon_p'> 
  <br/>
  <br/>
  Go to Raydium
  <br/>
  <br/>
      
  Go to  <a href='https://raydium.io/ '> https://raydium.io/ </a> 
   
   and connect your wallet. Paste the. $BRK Contract Address into the bottom box and click "I understand".
  <br/>
  <br/>
<a href=''>  <img src={uni} style={{width:"30%" , borderRadius:"20px"}}/></a>

  <br/>
  <br/>
      
      </p>
        </div></div></div></div>
        <br/>
        <br/>

</Grid>









<Grid item lg={12} md={12} xs={12} sm={12} className='centerall'>
  <br/>
  <br/>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">


        
      <p className='anon_p'> 
  <br/>
  <br/>
  Swap Solana for $BRK
  <br/>
  <br/>
      
  Enter how much Solana you want to buy with in the top box. Then click swap and then confirm when your wallet pops up.
  <br/>
  <br/>


  <br/>
  <br/>
      
      </p>
        </div></div></div></div>
        <br/>
        <br/>

</Grid>






















</Grid>




































































<br/>  <br/>



<Grid container spacing={4} id='Tokenomics'>
<Grid item lg={4} md={4} xs={12} sm={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">
      <img src={noot1} style={{width:"100%"}}/>
        
        </div></div></div></div>

</Grid>
<Grid item lg={4} md={4} xs={12} sm={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">
      <img src={noot2} style={{width:"100%" }}/>
        
        </div></div></div></div>

</Grid>
<Grid item lg={4} md={4} xs={12} sm={12} className='centerall'>
<div class="max-w-screen-lg mx-auto px-8 mb-20">
  <div class="flex justify-center">
    <div class="card"><div class="boarding">
      </div><div class="card-content">
      <img src={noot3} style={{width:"100%" }}/>
        
        </div></div></div></div>

</Grid>

</Grid><br/>  <br/><br/>  <br/>
    </div>
  )
}

export default Desktop